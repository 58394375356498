import React, { forwardRef, useContext, useEffect, useState } from "react";
import "./homepagelead.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { countryCodeList, generateLead } from "../Services/services";
import {
  PrimaryDisabledButton,
  PrimarySubmitButton,
} from "../../../utils/Buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormSubmissionSuccess from "../FormSubmissionSuccess/FormSubmissionSuccess";
import LoginOtp from "../Auth/Login/LoginOtp";
import FormModal from "../FormModal/FormModal";
import { UserContext } from "../hooks/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import { Tracker } from "../../../tracking/tracker";
import { FormSubmitPageEvent } from "../../../tracking/events/formSubmitPageEvent";
import { getFullUrlPath, LeadSource } from "../../../utils/utility";
import SnackbarComponent from "../Snackbar/Snackbar";
import { MetaLeadEvent } from "../../../tracking/events/leadEvent";
import SignUpUser from "../Auth/SignUp/SignUpUser";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--text-secondary-color)",
      },

      "&.Mui-focused fieldset": {
        borderColor: "var(--text-primary-color)",
      },
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "13px",

      color: "var(--text-secondary-color)",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "13px",
      color: "var(--text-primary-color)",
      height: "20px",
    },
    "& .MuiInputLabel-shrink": {
      fontSize: "18px",
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "var(--text-secondary-color)",
    },

    margin: "0px 0px",
  },
  selectField: {
    flex: 3,
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      "& fieldset": {
        borderRight: "0px",
      },
      "&.Mui-focused": {
        borderRight: "2px solid black",
        borderColor: "var(--text-primary-color)",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        textAlign: "right",
        direction: "rtl",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  phoneField: {
    flex: 10,
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

const HomePageLeadForm = forwardRef(
  ({ data, onItemClick, packageId, pageName }, ref) => {
    const history = useHistory();
    const location = useLocation();
        const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const [leadId, setLeadId] = useState(null);
    const isLoggedIn = localStorage.getItem("access_token");

    const { userInfo } = useContext(UserContext);
    const [isFormValid, setIsFormValid] = useState(false);
    const [contryCodeList, setContryCodeList] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false);
    const [openLeadModal, setOpenLeadModal] = useState(false);
    const [openLeadOtpModal, setOpenLeadOtpModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCountryId, setSelectedCountryId] = useState("");
    const [userEnteredFieldsArray, setUserEnteredFieldsArray] = useState([]);
    const [shouldShowSuccessView, setShouldShowSuccessView] = useState(false);
    const [isotpView, setIsOtpView] = useState(false);
    const [isFormsubmitted, setIsFormSubmitted] = useState(false);
    const fullUrlPath = getFullUrlPath(location);
    const classes = useStyles();
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const handleOpenSnackbar = () => {
      setOpenSnackBar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpenSnackBar(false);
    };
    const [formData, setFormData] = useState({
      name: userInfo?.name ? userInfo?.name : "",
      email: userInfo?.email ? userInfo?.email : "",
      phone: userInfo?.phone_number ? userInfo?.phone_number : "",
      countryCode: "",
    });
    useEffect(() => {
      if (userInfo) {
        setFormData({
          name: "",
          email: "",
          phone: userInfo.phone_number || "",
          countryCode: "",
        });
      }
    }, [userInfo]);
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setUserEnteredFieldsArray((prevKeysArray) => {
        if (!prevKeysArray.includes(name)) {
          return [...prevKeysArray, name];
        }
        return prevKeysArray;
      });
    };

    const getCountryCodeList = async () => {
      // setLoadingCountryList(true);
      const response = await countryCodeList();

      if (response.status >= 200 && response.status < 300) {
        // setLoadingCountryList(false);
        const defaultCountryCode = response.data.data.countries.filter(
          (data) => data.is_default === true
        );
        setSelectedCountry(defaultCountryCode[0]?.calling_code);
        setSelectedCountryId(defaultCountryCode[0]?.id);

        setContryCodeList(response.data.data.countries);
      }
    };

    const handleCallingCodeChange = (e) => {
      const { value } = e.target;

      setSelectedCountryId(value);
      const selectedCountry = contryCodeList.filter(
        (country) => country.id == value
      );
      if (selectedCountry && selectedCountry.length > 0) {
        const callingCode = selectedCountry[0].calling_code;
        setSelectedCountry(callingCode);
        setFormData({ ...formData, countryCode: callingCode });
      }
    };

    const submitLeadForm = async () => {
      setLoadingForm(true);
      const response = await generateLead(
        null,
        null,
        null,
        formData.name,
        formData.email,
        selectedCountry ? selectedCountry : formData.countryCode,
        formData.phone,
        pageName == "packagedetails" ? packageId : null,
        null,
        null,
        null,
        fullUrlPath,
        LeadSource.ON_PAGE,
        pageName
      );

      if (response.status >= 200 && response.status < 300) {
      
        Tracker.getInstance().trackEvent(new FormSubmitPageEvent());
        Tracker.getInstance().trackMetaEvent(new MetaLeadEvent(selectedCountry ? selectedCountry : formData.countryCode, formData.phone));
        setLoadingForm(false);
        if (response.data.data.should_verify_otp) {
          setLeadId(response.data.data.lead.id);
          setIsOtpView(response.data.data.should_verify_otp);
          setShouldShowSuccessView(true);
          handleOpenLeadOtpModal();
          setOpenSignUpModal(false);
          setOpenSignUpModal(true)
        } else {
          history.push("/thanks");
          sessionStorage.setItem("previousPage", fullUrlPath);
          // setIsFormSubmitted(true);
          // handleOpenLeadSuccessModal();
        }
      }
      if (response.status === 400) {
        handleOpenSnackbar();
        setLoadingForm(false);
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();

      const mobileNumberRegex = /^[1-9]\d{8,14}$/;
      const isValidMobile = mobileNumberRegex.test(formData.phone);
      if (isValidMobile) {
        submitLeadForm();
      }
    };
    useEffect(() => {
      getCountryCodeList();
    }, []);
    const handleOpenLeadSuccessModal = () => {
      setOpenLeadModal(true);
      setFormData({
        name: "",
        email: "",
        phone: userInfo.phone_number || "",
        countryCode: "",
      });
    };
    const handleOpenLeadOtpModal = () => {
      setOpenLeadOtpModal(true);
    };
    const handleCloseLeadOtpModal = () => {
      setOpenLeadOtpModal(true);
    };
    const handleCloseLeadSuccessModal = () => {
      setOpenLeadModal(false);
    };

    useEffect(() => {
      const isValid =
        formData.name && formData.email && formData.phone && selectedCountryId;
      setIsFormValid(isValid);
    }, [formData, selectedCountryId]);

    useEffect(() => {
      // Optional: You can log if fbq is loaded
      if (window.fbq) {
        console.log('fbq is loaded');
      } else {
        console.error('fbq is not loaded yet');
      }
    }, []);
    const handleCloseSignUpModal = () => {
      setOpenSignUpModal(false);
    };
    return (
      <>
        <SnackbarComponent
          openSnackBar={openSnackBar}
          handleCloseSnackbar={handleCloseSnackbar}
          handleOpenSnackbar={handleOpenSnackbar}
          message="Something Went Wrong! Please try again."
        />
        <FormModal
          openCustomizeModal={openLeadModal}
          handleCloseCustomizeModal={handleCloseLeadSuccessModal}
          children={<FormSubmissionSuccess userInfo={userInfo} />}

          // setCurrentView={setCurrentView}
        />
        <FormModal
          openCustomizeModal={openLeadOtpModal}
          handleCloseCustomizeModal={handleCloseLeadOtpModal}
          shouldShowSuccessView={shouldShowSuccessView}
          // children={<LoginOtp leadId={leadId} />}
          children={<SignUpUser   
             openSignUpModal={openSignUpModal}
          handleCloseSignUpModal={handleCloseSignUpModal}
          accessToken={isLoggedIn} />}
          countryCode={selectedCountry}
          mobileNumber={formData.phone}
       
        />

        <div
          class="lead-form-container"
          style={{ width: pageName == "packagedetails" ? "100%" : "90%" }}
          ref={ref}
        >
          <form
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onSubmit={handleSubmit}
          >
            <div>
              <h1>{data.title}</h1>
            </div>
            <div class="lead-input-group">
              <TextField
                size="small"
                name="name"
                type="text"
                required
                id="standard-required"
                style={{
                  width: window.innerWidth <= 768 ? "90%" : "100%",
                  flex: "2.5",
                }}
                className={classes.textField}
                value={formData.name}
                onChange={handleChange}
                label="Full Name"
                variant="outlined"
                autoComplete="name"
                // fullWidth
              />

              <TextField
                size="small"
                name="email"
                type="email"
                required
                id="standard-required"
                style={{
                  width: window.innerWidth <= 768 ? "90%" : "100%",
                  flex: "2.5",
                }}
                className={classes.textField}
                value={formData.email}
                onChange={handleChange}
                label="Email Id"
                variant="outlined"
                autoComplete="email"
                // fullWidth
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  flex: "4",
                  width: window.innerWidth <= 768 ? "90%" : "100%",
                }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  size="small"
                  style={{ flex: "4" }}
                  disabled={userInfo ? true : false}
                  className={`${classes.textField} ${classes.selectField}`}
                  name="countryCode"
                  label="Country"
                  value={selectedCountryId || ""}
                  onChange={handleCallingCodeChange}
                  SelectProps={{
                    native: true,
                    displayEmpty: true,
                  }}
                  variant="outlined"
                  // fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // required
                >
                  {contryCodeList?.map((country) => (
                    <option
                      key={country.id}
                      value={country.id}
                      style={{ padding: "30px 0" }}
                    >
                      {country.name} (+{country.calling_code})
                    </option>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  type="tel"
                  style={{ flex: "8" }}
                  disabled={userInfo ? true : false}
                  required
                  id="phone-number"
                  className={`${classes.textField} ${classes.phoneField}`}
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  label="Phone Number"
                  variant="outlined"
                  autoComplete="tel"
                  // fullWidth
                />
                {/* {error && (
                  <p className="lead-form-error-text">
                    <ErrorIcon
                      style={{ fontSize: "10px", marginRight: "2px" }}
                    />{" "}
                    {error}
                  </p>
                )} */}
              </div>
              {isFormValid ? (
                <PrimarySubmitButton
                  onClick={
                    pageName !== "packagedetails"
                      ? () => onItemClick(data.title)
                      : undefined
                  }
                  type="submit"
                  className="homepage-lead-button"
                  disabled={!isFormValid}
                >
                  {" "}
                  {loadingForm ? (
                    <CircularProgress color="inherit" size={12} />
                  ) : (
                    "Submit"
                  )}
                </PrimarySubmitButton>
              ) : (
                <PrimaryDisabledButton className="disabled-homepage-lead-button">
                  Submit
                </PrimaryDisabledButton>
              )}
            </div>
            <p>
              By submitting this form, you agree with our{" "}
              <a href="/Conditions" class="privacy-policy">
                Terms & Conditions
              </a>
            </p>
          </form>
        </div>
      </>
    );
  }
);

export default HomePageLeadForm;
